<template>
  <ACRUDTable
    ref="crud_table"
    :fields="
      fields || [
        //'id',
        { key: 'receipt_id', label: '# Receipt', helper: { id: 'receipt.consecutive' } },
        //helpers.inputType,
        //helpers.bankAccount,
        helpers.monthName,
        { key: 'month_id', _classes: 'hide' },
        helpers.identifierText,
        /*helpers.building,
        getHelperObject('building_type', 'building.building_type_id'),
        getHelperObject('property_unit', 'building.property_unit_id'),*/
        helpers.ownerNameText,
        { key: 'consecutive', label: 'Invoice', helper: { id: 'invoice.consecutive' } },
        { ...helpers.currencyValue, key: 'invoice_value', helper: { id: 'invoice.value' } },
        { ...helpers.currencyValue, label: 'Payment value' },
        'payment_date',
        { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 180px;' }
      ]
    "
    :config="{
      url: 'property_payments', // + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''),
      //route: '/no-users/payments',
      name: 'payment',
      lname: 'property_payments',
      display_name: $t('Payments'),
      params: { _with: 'receipt,building', _lists: 'months,imputations,bank_accounts,building_types,property_units', building_id: this.building_id || 0, ...(params || {}) },
      //filters: filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'owner_name', use: 'owner.name' }, 'bank_account'] : [],
      noCreate: true,
      noOptions: true,
      ...(config || {})
    }"
    @preparedHelpers="onPreparedHelpers"
  >
    <template #options="{item}">
      <td style="text-align: center; white-space: nowrap;">
        <!--CButton type="button" class="mr-2" color="info" @click="getInvoice('download', item)"><CIcon name="cil-cloud-download"/></CButton-->
      </td>
    </template>
  </ACRUDTable>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'NU-Payments',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  watch: {
    building_id(new_value, old_value) {
      if (new_value && new_value !== old_value) this.onGetData(new_value)
    }
  },
  data() {
    return {
      period_type_id: 'REC' // TODO: Why ??? Restricted ?
    }
  },
  methods: {
    onGetData(building_id) {
      //if (month_id) {
      //this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { building_id }, true)
      //}
    }
  }
}
</script>
