var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ACRUDTable',{ref:"crud_table",attrs:{"fields":_vm.fields || [
      //'id',
      { key: 'receipt_id', label: '# Receipt', helper: { id: 'receipt.consecutive' } },
      //helpers.inputType,
      //helpers.bankAccount,
      _vm.helpers.monthName,
      { key: 'month_id', _classes: 'hide' },
      _vm.helpers.identifierText,
      /*helpers.building,
      getHelperObject('building_type', 'building.building_type_id'),
      getHelperObject('property_unit', 'building.property_unit_id'),*/
      _vm.helpers.ownerNameText,
      { key: 'consecutive', label: 'Invoice', helper: { id: 'invoice.consecutive' } },
      Object.assign({}, _vm.helpers.currencyValue, {key: 'invoice_value', helper: { id: 'invoice.value' }}),
      Object.assign({}, _vm.helpers.currencyValue, {label: 'Payment value'}),
      'payment_date',
      { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 180px;' }
    ],"config":Object.assign({}, {url: 'property_payments', // + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''),
    //route: '/no-users/payments',
    name: 'payment',
    lname: 'property_payments',
    display_name: _vm.$t('Payments'),
    params: Object.assign({}, {_with: 'receipt,building', _lists: 'months,imputations,bank_accounts,building_types,property_units', building_id: this.building_id || 0}, (_vm.params || {})),
    //filters: filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'owner_name', use: 'owner.name' }, 'bank_account'] : [],
    noCreate: true,
    noOptions: true},
    (_vm.config || {}))},on:{"preparedHelpers":_vm.onPreparedHelpers},scopedSlots:_vm._u([{key:"options",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","white-space":"nowrap"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }